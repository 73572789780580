import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const useCompanyTransaction = () => {
  const BANK_TRANSFER_ID = 1;
  const CASH_ID = 2;

  const toast = useToast();

  const createTransaction = async (transaction) => {
    try {
      return await store.dispatch('companyTransaction/create', transaction);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const fetchTransactionStatus = async () => {
    try {
      return await store.dispatch('companyTransaction/getTransactionStatus');
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error fetching the transaction status',
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const fetchTransactionTypes = async () => {
    try {
      return await store.dispatch('companyTransaction/getTransactionTypes');
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error fetching the transaction types',
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const fetchTransactions = async (params) => {
    try {
      return await store.dispatch('companyTransaction/getTransactions', {
        params,
      });
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error fetching the transaction list',
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const fetchCompanyTransactions = async (companyId, params) => {
    try {
      return await store.dispatch(
        'companyTransaction/getTransactionsByCompany',
        {
          companyId,
          params,
        }
      );
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error fetching the company transactions',
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const downloadAccountStatement = async (companyId, params) => {
    try {
      return await store.dispatch('company/downloadAccountStatement', {
        companyId,
        params,
      });
    } catch (error) {
      const title =
        error.response.status === 404
          ? 'No transaction found in date range'
          : 'Error downloading the company account statement';
      toast.error({
        component: ToastificationContent,
        props: {
          title,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const sendAccountStatement = async (companyId, params) => {
    try {
      await store.dispatch('company/sendAccountStatement', {
        companyId,
        params,
      });

      toast.success({
        component: ToastificationContent,
        props: {
          title: 'Email sent successfully!',
          icon: 'CheckIcon',
        },
      });
      return true;
    } catch (error) {
      const title =
        error.response.status === 404
          ? 'No transaction found in date range'
          : 'Error sending the email';
      toast.error({
        component: ToastificationContent,
        props: {
          title,
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const cancelTransaction = async (transactionId, description) => {
    try {
      await store.dispatch('companyTransaction/cancelTransaction', {
        transactionId,
        description,
      });
      return true;
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'An error occurred.',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const resolveTransactionStatusVariantAndIcon = (transactionStatus) => {
    if (transactionStatus.isPaid) return 'success';
    if (transactionStatus.isPending) return 'warning';
    return 'secondary';
  };

  const resolveTransactionTypeVariantAndIcon = (id) => {
    if (id === BANK_TRANSFER_ID)
      return { variant: 'success', icon: 'CheckIcon' };
    if (id === CASH_ID) return { variant: 'info', icon: 'DollarSignIcon' };
    return { variant: 'secondary', icon: 'XIcon' };
  };

  return {
    // Method
    createTransaction,
    downloadAccountStatement,
    fetchCompanyTransactions,
    fetchTransactions,
    fetchTransactionStatus,
    fetchTransactionTypes,
    resolveTransactionStatusVariantAndIcon,
    resolveTransactionTypeVariantAndIcon,
    sendAccountStatement,
    cancelTransaction,
  };
};

export default useCompanyTransaction;
